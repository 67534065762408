import React, { Component } from "react";

import LoginAdmin from "views/LoginAdmin";
import PARAMS from "utils/PARAMS";
import * as API from "utils/API_V2";
import GridContainer from "components/Grid/GridContainer";
import Loader from "react-spinners/RingLoader";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// core components
import { createBrowserHistory } from "history";
import Admin from "layouts/Admin";
import NotFound from './NotFound';

import "assets/fonts/Montserrat-Regular.ttf";
import "assets/css/material-dashboard-react.css?v=1.9.0";

const hist = createBrowserHistory();

class App extends Component {
  state = {
    apiToken: localStorage.getItem("apiToken") || "",
    user: localStorage.getItem("userType") || "entidad",
    admin: false,
    load: false,
    permiso: null
  };

  setApiToken = token => {
    this.setState({ apiToken: token });
  };

  isAdmin = async(e) => {
    const res = await API.isAdminAuth();
    if (res.error) {
      // toast("Se ha producido un error", {type: "warning"});
    }else{
      if (res.data) {
        this.setState({ admin: res.data });
      }
      let url = PARAMS.baseUrl + 'administrador';
      if (window.location.href.includes(url)) {
        if (!res.data && this.state.apiToken !== "") {
          window.location.href = PARAMS.baseUrl + 'entidad';
        }else {
          this.setState({ load: true });
        }
      }else {
        if (res.data && this.state.apiToken !== "") {
          window.location.href = PARAMS.baseUrl + 'administrador';
        }else {
          this.setState({ load: true });
        }
      }
    }
  }

  componentDidMount(){
    this.setState({ load: true });
  }

  render() {
    if (this.state.load) {
      let url = PARAMS.baseUrl + 'administrador';
      return (
        <>
          {this.state.apiToken === "" && <LoginAdmin setApiToken={this.setApiToken} setPermiso={this.setPermiso}/>}
          {this.state.apiToken !== "" && (
            <>
              <Router history={hist}>
                <Switch>
                  <Route path="/administrador" component={(props) => <Admin {...props}/>} />
                  <Redirect from="/" to="/administrador/dashboard" />
                  <Route component={ NotFound }/>
                </Switch>
              </Router>
              <ToastContainer autoClose={3000}/>
            </>
          )}
        </>
      );
    }else{
      return(
        <GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
          <Loader color={PARAMS.firstColor} size={80} />
        </GridContainer>
      )
    }

  }
}

export default App;
