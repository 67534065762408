/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Divider from '@material-ui/core/Divider';
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import PARAMS from 'utils/PARAMS';

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes } = props;

  var links = (
    <List className={classes.list} style={{paddingBottom: 50}}>
      {routes.map((prop, key) => {
        if (prop.sideBarVisible) {
          var active = activeRoute(PARAMS.panel_url + prop.path);
          var listItemClasses = classNames({
            [" " + classes[color]]: active
          });

          return (<React.Fragment key={key}>
            {(key > 0) && <Divider style={{marginRight: 30, marginLeft: 30}}/>}
            <NavLink
              to={PARAMS.panel_url + prop.path}
              className={classes.item}
              activeClassName="active"
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                <prop.icon
                  className={classNames(active ? classes.itemIconActive : classes.itemIcon)}
                />
                <ListItemText
                  primary={prop.name}
                  className={classNames(active ? classes.itemTextActive : classes.itemText)}
                  disableTypography={true}
                  style={{fontFamily: 'Montserrat'}}
                />
              </ListItem>
            </NavLink>
          </React.Fragment>);
        }

      })}
    </List>
  );

  var brand = (
    <div className={classes.logo}>
      <div
        className={classNames(classes.logoLink)}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        <div style={{marginTop: 45}}>
          {logoText}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              // style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              // style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red", "black"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
