import { lazy } from 'react';

import PeopleIcon from '@material-ui/icons/People';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import DvrIcon from '@material-ui/icons/Dvr';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "DASHBOARD",
    icon: DashboardIcon,
    component: lazy(() => import('views/dashboard')),
    sideBarVisible: true,
    modelo: 'dashboard'
  },
  {
    path: "/usuarios",
    name: "USUARIOS",
    icon: PeopleIcon,
    component: lazy(() => import('views/usuarios/Usuarios')),
    sideBarVisible: true,
    modelo: 'usuarios'
  },
  {
    path: "/sensores",
    name: "SENSORES",
    icon: ViewDayIcon,
    component: lazy(() => import('views/interactuadores/Interactuadores')),
    layout: "/administrador",
    sideBarVisible: true,
    modelo: 'sensores'
  },
  {
    path: "/permisos",
    name: "PERMISOS",
    icon: PermIdentityIcon,
    component: lazy(() => import('views/permisos/Permisos')),
    sideBarVisible: true,
    modelo: 'permisos'
  },
  {
    path: "/logs",
    name: "LOGS",
    icon: DvrIcon,
    component: lazy(() => import('views/logs/Logs')),
    sideBarVisible: true,
    modelo: 'logs'
  },
  {
    path: "/perfil",
    name: "PERFIL",
    icon: ViewDayIcon,
    component: lazy(() => import('views/usuarios/UserProfile')),
    sideBarVisible: false,
    modelo: null
  }
];

export default dashboardRoutes;
