import axios from "axios";
import PARAMS from "utils/PARAMS";
import { toast } from 'react-toastify';
import * as fakeData from "utils/fakeData";
var fake = false;

// Instancia para api
var instance_api = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 10000
});

// Global interceptor request
instance_api.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});

// Global interceptor response
instance_api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
instance_api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        if (localStorage.getItem("apiToken")) {
          toast("Sesión caducada", {type: "error"});
          instance_api.get('logout');
          localStorage.removeItem('apiToken');
          localStorage.removeItem('userType');
          window.location.replace(PARAMS.baseUrl);
        }else{
          toast("Usuario no autorizado", {type: "error"});
        }
        break;
      case 404:
        toast("Url no disponible", {type: "error"});
        break;
      case 500:
        break;
      default:

    }
  }else{
    toast("No se ha podido establecer conexión", {type: "error"});
  }

  return Promise.reject(error);
});

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null
  };

  try {
    resolved.data = await promise;
  } catch(e) {
    resolved.error = e;
  }

  return resolved;
}

// Ruta para verificar tipo de usuario (token)
export async function isAdminAuth() {
  if (fake) {
    return {data: true};
  }else{
    return await resolve(
      instance_api.post('isAdminAuth').then(res => res.data)
    )
  }
}

// Ruta para verificar tipo de usuario (no token)
export async function isAdminGuest() {
  if (fake) {
    return {data: true};
  }else{
    return await resolve(
      instance_api.post('isAdminGuest').then(res => res.data)
    )
  }
}

export async function login(email, password, userType) {
  if (fake) {
    return {data:{token: 'asdasdasd'}};
  }else{
    return await resolve(
      instance_api.post('login', { email, password, userType }).then(res => res.data)
    )
  }
}

export async function register(nombre, email, password, userType) {
  if (fake) {
    return {data:{token: 'asdasdasd'}};
  }else{
    return await resolve(
      instance_api.post('register', { nombre, email, password, userType }).then(res => res.data)
    )
  }
}

export async function resetPass(email) {
  if (fake) {
    return {data: 'ok'};
  }else{
    return await resolve(
      instance_api.post('resetPassword/create', { email }).then(res => res.data)
    )
  }
}

export async function logout() {
  if (fake) {
    return {data: 'ok'};
  }else{
    return await resolve(
      instance_api.get('logout').then(res => res.data)
    )
  }
}

export async function getPermiso() {
  if (fake) {
    return {data: fakeData.permisos};
  }else{
    return await resolve(
      instance_api.post('permiso/index').then(res => res.data)
    )
  }
}

export async function getUser() {
  if (fake) {
    return {data: fakeData.myUser};
  }else{
    return await resolve(
      instance_api.post('usuarios/show').then(res => res.data)
    )
  }
}

export async function userIndex(search, page, perPageData) {
  if (fake) {
    return {data: fakeData.clientes};
  }else{
    return await resolve(
      instance_api.post('usuarios/index', { search, page, perPageData }).then(res => res.data)
    )
  }
}

export async function userStore(nombre, email, permiso_id, password, apellidos, puesto, company) {
  if (fake) {
    return {data: 'ok'};
  }else{
    return await resolve(
      instance_api.post('usuarios/store', { nombre, email, permiso_id, password, apellidos, puesto, company }).then(res => res.data)
    )
  }
}

export async function userUpdate(id, nombre, email, permiso_id, password, apellidos, puesto, company) {
  if (fake) {
    return {data: 'ok'};
  }else{
    return await resolve(
      instance_api.post(`usuarios/update/${id}`, { nombre, email, permiso_id, password, apellidos, puesto, company }).then(res => res.data)
    )
  }
}

export async function userDestroy(id) {
  if (fake) {
    return {data: 'ok'};
  }else{
    return await resolve(
      instance_api.post(`usuarios/destroy/${id}`).then(res => res.data)
    )
  }
}

export async function logIndex() {
  if (fake) {
    return {data: fakeData.logs};
  }else{
    return await resolve(
      instance_api.post('log/index').then(res => res.data)
    )
  }
}

export async function permisosList(search) {
  if (fake) {
    return {data: fakeData.permisosList};
  }else{
    return await resolve(
      instance_api.post('permiso/list', { search }).then(res => res.data)
    )
  }
}

export async function permisoStore(nombre, permiso_modelos) {
  if (fake) {
    return {data: 'ok'};
  }else{
    return await resolve(
      instance_api.post('permiso/store', { nombre, permiso_modelos }).then(res => res.data)
    )
  }
}

export async function permisoUpdate(id, nombre, permiso_modelos) {
  if (fake) {
    return {data: 'ok'};
  }else{
    return await resolve(
      instance_api.post(`permiso/update/${id}`, { nombre, permiso_modelos }).then(res => res.data)
    )
  }
}

export async function permisoDestroy(id) {
  if (fake) {
    return {data: 'ok'};
  }else{
    return await resolve(
      instance_api.post(`permiso/destroy/${id}`).then(res => res.data)
    )
  }
}

export async function sensorAforoIndex(search) {
  return await resolve(
    instance_api.post('sensorAforo/index', {search}).then(res => res.data)
  )
}

export async function saveMargen(margen) {
  return await resolve(
    instance_api.post('sensorAforo/saveMargen', {margen}).then(res => res.data)
  )
}

export async function getAforo() {
  return await resolve(
    instance_api.post(`sensorAforo/aforo`).then(res => res.data)
  )
}

export async function sensorAforoStore(titulo, referencia, limite, actual) {
  return await resolve(
    instance_api.post('sensorAforo/store', { titulo, referencia, limite, actual }).then(res => res.data)
  )
}

export async function sensorAforoUpdate(id, titulo, referencia, limite, actual) {
  return await resolve(
    instance_api.post(`sensorAforo/update/${id}`, { titulo, referencia, limite, actual }).then(res => res.data)
  )
}

export async function sensorAforoDestroy(id) {
  return await resolve(
    instance_api.post(`sensorAforo/destroy/${id}`).then(res => res.data)
  )
}
