import React, { useState, useEffect, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import Loader from "react-spinners/RingLoader";
import GridContainer from "components/Grid/GridContainer";
import PARAMS from 'utils/PARAMS';
import * as API from "utils/API_V2";
import { toast } from 'react-toastify';

import rutas from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

let ps;

export default function Admin({ ...rest }) {
  const [routes, setRoutes] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    setIsLoad(false);
    loadPermiso();
  }, []);

  const loadPermiso = async() => {
    const res = await API.getPermiso();
    if (res.error) {
      toast("Se ha producido un error en la carga de rutas", {type: "warning"});
    }else{
      loadRoutes(res.data);
    }
  }

  const loadRoutes = (res) => {
    var auxRutas = [];
    rutas.map(function(ruta, key) {
      if (ruta.modelo == null) {
        auxRutas.push(ruta)
      }
      let permisoModelo = res.modelo.find(el => el.modelo == ruta.modelo);
      if (ruta.modelo && permisoModelo && Boolean(permisoModelo.ver)) {
        auxRutas.push(ruta)
      }
    })
    setRoutes(auxRutas);
    setIsLoad(true);
  };

  const useStyles = makeStyles(styles);
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(PARAMS.sideBarImg);
  const [color, setColor] = React.useState(PARAMS.sideBarColor);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [showSiderbar, setShowSidebar] = React.useState(true);

  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>

      {(showSiderbar && isLoad) && <>
        <Sidebar
          routes={routes}
          logoText={PARAMS.proyectName}
          logo={PARAMS.sideBarIcon}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...rest}
        />
      </>}
      <div className={showSiderbar ? classes.mainPanel : classes.mainPanel_nodrawer} ref={mainPanel}>
        {isLoad && <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          toggleSidebar={() => setShowSidebar(!showSiderbar)}
          sidebarStatus={showSiderbar}
          {...rest}
        />}

        <div className={classes.content}>
          <div className={classes.container}>
          {isLoad ?
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                {routes.map((prop, key) => {
                  let Componente = prop.component;
                  return (
                    <Route
                      path={PARAMS.panel_url + prop.path}
                      component={(props) => <Componente {...props}/>}
                      key={key}
                    />
                  );
                  return null;
                })}
                <Redirect from={PARAMS.panel_url} to={`${PARAMS.panel_url}/dashboard`} />
              </Switch>
            </Suspense>
            :
            <GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
              <Loader color={PARAMS.firstColor} size={80} />
            </GridContainer>
          }
          </div>
        </div>
        <Footer />
        {PARAMS.personalizacion && <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        />}
      </div>
    </div>

  );
}
